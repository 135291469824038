import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <section className="contact-head">
      <div className="container">
        <div className="contact-intro">
        </div>
        <div className="contact-content">
          <p className="contact-text">If you'd like to talk about a job or a project, or even just go for a drink, feel free to send me a massage.</p>
          <p className="success-message">Thanks for getting in contact!</p>
          <Link to="/contact"><span className="button-again">Send Another Message</span></Link>
        </div>
      </div>
    </section>
  </Layout>
);
  
  export default ContactPage